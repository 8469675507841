ScrollWatch = null
### @ngInject ###
ScrollWatch = ($rootScope) ->
    link = (scope, elem, attr) ->
        start     = 0
        threshold = 150
        elem.bind 'scroll', (e) ->
            if e.detail.scrollTop - start > threshold
                $rootScope.slideHeader = on
            else
                $rootScope.slideHeader = off
            if $rootScope.slideHeaderPrevious >= e.detail.scrollTop - start
                $rootScope.slideHeader = off
            $rootScope.slideHeaderPrevious = e.detail.scrollTop - start
            $rootScope.$apply()
            return
        return


    directive =
        restrict: 'EA'
        link: link
    directive


angular.module 'app'
.directive 'glScrollWatch', ScrollWatch
